
.flipIn {
    // transform: translateX(1000%);
    animation: flipIn 600ms ease-out;
    transform-origin: 50vw 50vh;
    overflow: hidden;
    // animation-delay: 1000ms;
    // backface-visibility: hidden;
}
@keyframes flipIn {
    
    0%{
        transform: scale(0) rotate(0);
        opacity: 0;
    } 
    100%{
        transform: scale(1) rotate(1080deg);
        opacity: 1;
    }

    // 0%{transform: scale(0);} 
    // 50%{transform: scale(1);} 
    // 100%{transform: scale(1);} 
}
.scaleIn {
    // transform: translateX(1000%);
    animation: scaleIn 500ms ease-out;
    transform-origin: 50vw 50vh;
    // overflow: hidden;
    // animation-delay: 1000ms;
    // backface-visibility: hidden;
}
@keyframes scaleIn {
    0%{transform: scale(0.9); opacity: 0;} 
    // 90%{transform: scale(1.1);} 
    100%{transform: scale(1); opacity: 1;} 
}

.translateIn {
    // transform: translateX(1000%);
    animation: translateIn 300ms ease-out;
    // transform-origin: 50vw 50vh;
    overflow: hidden;
    // animation-delay: 1000ms;
    // backface-visibility: hidden;
}
@keyframes translateIn {
    0%{opacity: 0;} 
    90%{transform: translateX(20%);} 
    100%{transform: translateX(0); opacity: 1;} 
}


.scrollme
{
    perspective:600px;
}

.hideNav {
    transform: translateY(-40%);
    opacity: 0;
    pointer-events: none;
}