.skewed {
    position: relative;
}
div.skewed {
    margin-bottom: 120px;
    margin-top: 120px;
}
.skewed::before {
    content: "";
    position: absolute;
    top: -50px;
    left: 0;
    right: 0;
    bottom:  -50px;
    // width: 100vw;
    // height: 100px;
    background: radial-gradient(circle, $red_1 0%, rgba(17,17,17,1) 100%);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.637);
    // background-attachment: fixed;
    // background: inherit;
    transform: skewY(-5deg);
    z-index: -1;
    @media (min-width: 575px) {
        transform: skewY(-2deg);
    }
}

// .skewed::after {
//     content: "";
//     position: absolute;
//     bottom: -50px;
//     left: 0;
//     right: 0;
//     // width: 100vw;
//     height: 100px;
//     background: radial-gradient(circle, rgba(51,102,153,1) 0%, rgba(17,17,17,1) 100%);
//     transform: skewY(-2deg);
//     z-index: -1;
// }