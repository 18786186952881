#main {
    // transform: translateX(1000%)
    // transform-style: preserve-3d;
    // perspective: 300px;
    // transform: perspective(5000px) rotateY(90deg);
    // box-shadow: $box_shadow_3;
    border-left: 1px solid #000;
    // overflow-x: hidden;
}
// body {
//   filter: hue-rotate(180deg);
// }

/* width */
::-webkit-scrollbar {
	width: 8px;
}

  /* Track */
::-webkit-scrollbar-track {
	// background-color: $yellow_1;
	background-color: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background:$red_1; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: $red_2;
}