.news_card {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    min-height: 19rem;
    height: 100%;
    width: 100%;
    // max-height: 500px;
    box-shadow: $box_shadow_0;
    // display: flex;
    // flex-flow: column;
    // justify-content: stretch;
}
.news_card::after {
    position: absolute;
    content: "";
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    background: linear-gradient(180deg, transparent 0%, rgba(17,17,17,0.5662640056022409) 73%);
    pointer-events: none;
    z-index: 1;
}
.news_card_image_holder {
    width: 100%;
    height: 100%;
}
.news_card_image_holder a {
    width: 100%;
    height: 100%;
    display: block;
    border-radius: 5px;
    text-align: center;
    overflow: hidden;
    position: relative;
}
.news_card_image {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    // min-height: 300px;
    // max-height: 500px;
    object-fit: cover;
    object-position: center;
}
.news_card_content {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    // height: auto;
    background: linear-gradient(180deg, transparent 0%, #bd2030a1 64%, rgba(17,17,17,1) 100%);
    padding: 20px;
    z-index: 2;
}
.news_card_content>span {
    // background-color: rgba(255, 255, 255, 0.918);
    padding: 3px 5px;
    border-radius: 4px;
    color: #fff !important;
    border: 1px solid #fff;
}
.news_card_content>h3 {
    width: fit-content;
    font-size: 1.3rem;
    margin: 10px 0;
}
.news_card_content>h3 a {
    position: relative;
}
.news_card_content>h3 a::before {
    content: "";
    position: absolute;
    width: 0%;
    height: 100%;
    left: 0;
    top: 0;
    border-bottom: 1px solid #fff;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    z-index: -1;
}
.news_card:hover .news_card_content>h3 a::before {
    width: 100%;
}
.news_card_content a {
    color: #fff !important;
}
.news_card_content a:hover {
    color: $red_2 !important;
}
.news_card_content_date{
    color: rgb(255, 255, 255) !important;
}

// mini card
.news_minicard {
    display: flex;
    background: radial-gradient(circle, $red_1 0%, rgba(17,17,17,1) 100%);
    border-radius: 10px;
    padding: 10px;
    position: relative;
    box-shadow: $box_shadow_0;
    align-items: center;
}
.news_minicard_image_holder {
    width: 100px;
    height: 100px;
    overflow: unset !important;
    border-radius: 10px;
    @media (min-width: 575px) {
        width: 150px;
        height: 150px;
    }
}
.news_minicard_image {
    display: block;
    width: 100px;
    height: 100px;
    object-fit: cover;
    object-position: center;
    max-width: unset !important;
    border-radius: 10px;
    @media (min-width: 575px) {
        width: 150px;
        height: 150px;
    }
}
.news_minicard_content {
    padding: 0 10px;
    padding-bottom: 30px;
    position: relative;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    min-height: 100px;
    @media (min-width: 575px) {
        min-height: 150px;
    }
}
.news_minicard_content>span {
    // background-color: rgba(255, 255, 255, 0.918);
    font-size: 0.65rem;
    padding: 3px 5px;
    border-radius: 4px;
    color: #fff !important;
    border: 1px solid #fff;
    @media (min-width: 575px) {
        font-size: 0.8rem;
    }
}
.news_minicard_content>h3, .news_minicard_content_date {
    margin-bottom: 0 !important;
}
.news_minicard_content h3 {
    margin-top: 10px;
    line-height: 1.1rem;
    font-weight: 400;
    font-size: 0.85rem;
    @media (min-width: 575px) {
        line-height: 1.4rem;
        font-size: 1.4rem;
    }
}
.news_minicard_content h3 a{
    color: #fff !important;
    @media (min-width: 575px) {
        font-size: 1.2rem;
    }
}

.news_minicard_content_date{
    color: rgb(255, 255, 255) !important;
    position: absolute;
    bottom: 0px;
    left: 10px;
    font-weight: 300;
    font-size: 0.8rem;
}
.news_minicard_content_date>a {
    color: $red_2 !important;
}
// .news_minicard_content