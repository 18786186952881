.main-news-slider-area {
    // background: radial-gradient(circle, rgba(51,102,153,1) 0%, #111 100%);
    margin-bottom: 120px;
}
.slider-content {
    background: linear-gradient(180deg, transparent 0%, #bd202f96 64%, #111 100%);
}
.slider-content>.tag {
    background-color: transparent !important;
    padding: 3px 5px;
    border-radius: 4px;
    color: #fff !important;
    border: 1px solid #fff;
}
.side_card_container {
    display: flex;
    flex-flow: column;
    gap: 10px;
}