
/* popup */
.popup-overlay {
    position: fixed;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    z-index:1000;
	transform: translateY(-120%);
    opacity: 0;
    // transition: all 700ms ease-in-out;
    // transition-property: opacity, transform;
    pointer-events: none;
    background-color: rgba(0,0,0,0.7);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;

    // -webkit-backdrop-filter: blur(3px);
    // backdrop-filter: blur(3px);
    
}
    .popup-div {
        position: relative;

        // transform: scale(1.05);
        transition: transform 150ms ease-in;
		z-index:1001;
        box-sizing: border-box;
        
        min-width: 300px;
        min-height: 200px;
        max-width: 100vw;
        max-height: 100vh;
        
        width: 100vw;
        height: 100vh;
        
        overflow-x: hidden;
        overflow-y: auto;
        scroll-behavior: smooth;
        
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
    }
        // .popup-div-content {
        //     -webkit-box-shadow: 0px 0px 14px 0px rgba(0,0,0,0.3);
        //     -moz-box-shadow: 0px 0px 14px 0px rgba(0,0,0,0.3);
        //     box-shadow: 0px 0px 14px 0px rgba(0,0,0,0.3);
        //     background-color: #fff;
            
        //     padding: 0.3rem;
		// 	box-sizing: border-box;
        //     z-index: 22;
		// }
.show-popup {
	transform: translateY(0);
    pointer-events: auto;
    opacity: 1;
}
// .show-popup .popup-div {
// 	transform: scale(1);
// }
.popup-close {
    position: fixed;
    z-index: 24;
    top: 10px;
    right: 10px;
    
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    border-radius: 50px;
    background-color: rgba(255,255,255,1);
}

// only for bootstrap icon
.popup-close-icon {
    transform: scale(2.3);
}
.popup-close-icon::before {
    vertical-align: middle !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
//////////////////////////

// .loader_container {
// 	position: fixed;
// 	left: 0;
// 	right: 0;
// 	top: 0;
// 	bottom: 0;
// 	margin: auto;
// 	z-index: 1000;
// 	display: none;
// 	opacity: 0;
//     background-color: rgba(0,0,0,0.3);
// }
@media screen and (min-width: 768px) { 
    .popup-div {
        width: 90vw;
        height: 85vh;
    }
    // .popup-div-content {
    //     padding: 1rem;
    // }
}
@media screen and (min-width: 992px) { 
    .popup-div {
		max-width: 980px;
	}
}