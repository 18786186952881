#main {
  border-left: 1px solid #000;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #bd202f;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ff661f;
}

.skewed {
  position: relative;
}

div.skewed {
  margin-bottom: 120px;
  margin-top: 120px;
}

.skewed::before {
  content: "";
  position: absolute;
  top: -50px;
  left: 0;
  right: 0;
  bottom: -50px;
  background: radial-gradient(circle, #bd202f 0%, #111111 100%);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.637);
  transform: skewY(-5deg);
  z-index: -1;
}
@media (min-width: 575px) {
  .skewed::before {
    transform: skewY(-2deg);
  }
}

.top-header-area {
  background: radial-gradient(circle, #bd202f 0%, #111111 100%);
}

.navbar {
  align-items: stretch !important;
}

.navbar-area {
  position: sticky !important;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  background: radial-gradient(circle, #bd202f 0%, #111111 100%) !important;
  transition: 0.5s;
}

.meanmenu-reveal span {
  background-color: #fff !important;
}

.meanclose {
  color: #fff !important;
}

.mean-container .mean-nav ul li a.mean-expand {
  text-align: center;
  width: 24px !important;
  box-shadow: inset 0px 0px 12px rgba(0, 0, 0, 0.3);
}

.mean-container .mean-nav ul li a.mean-clicked {
  box-shadow: inset 0px 0px 12px rgba(0, 0, 0, 0.704);
}

.mean-nav {
  background: radial-gradient(circle, #bd202f 0%, #111111 100%) !important;
  overflow-x: hidden;
  max-height: 70vh;
}

.mean-nav .dropdown-menu, .mean-nav .nav-item .dropdown-menu {
  background: radial-gradient(circle, #bd202f 0%, #111111 100%) !important;
}

.mean-clicked {
  background-color: rgba(255, 255, 255, 0.247) !important;
}

.main-navbar {
  background: radial-gradient(circle, #bd202f 0%, #111111 100%);
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.45) !important;
}

.main-navbar a, .mean-nav a {
  color: #fff !important;
  background-color: transparent !important;
  border-top-color: transparent !important;
}

.main-navbar a:hover {
  color: #ff661f !important;
}

.main-navbar .dropdown-menu {
  background: radial-gradient(circle, #bd202f 0%, #111111 100%) !important;
  border-radius: 10px !important;
}

.logo_img {
  height: 45px;
}

.search_button {
  padding: 0 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  border: none;
  transition: all 250ms ease-in-out;
  transition-property: color, background-color;
  position: relative;
}

.search_button:hover {
  color: #ff661f;
}

.search_container {
  width: 90%;
  max-width: 500px;
}

.search_container ::placeholder {
  color: rgba(255, 255, 255, 0.788);
}

.search_input {
  padding: 15px;
  flex-grow: 1;
  border: 2px solid #fff;
  outline: 2px solid #fff !important;
  transition: outline-offset 250ms linear;
  background-color: transparent;
  color: #fff;
}

.search_button_submit {
  padding: 10px 20px;
  color: #fff;
  outline: 2px solid #fff !important;
  background-color: transparent;
  border: 2px solid #fff;
  transition: outline-offset 250ms linear;
  cursor: pointer;
  margin: 0 10px;
  min-width: 60px;
}

.search_button_submit:hover,
.search_button_submit:focus,
.search_input:hover,
.search_input:focus {
  outline-offset: -8px;
}

.banner_container {
  max-height: 283px;
  overflow: hidden;
  text-align: center;
  display: block;
}

.banner_image {
  object-fit: cover;
}

.banner_mobile {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.856);
  z-index: 5;
}

.close_banner_mobile_icon {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 6;
  padding: 8px;
}

.cookie_buttons_container {
  position: fixed;
  z-index: 10;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-content: center;
  /* line-height: 1.4; */
  padding: 15px;
  background: linear-gradient(90deg, #111 0%, #bd202f 40%);
}

.cookie_text {
  color: #fff;
}

.cookie_button {
  padding: 10px 20px;
  color: #fff;
  outline: 2px solid #fff !important;
  background-color: #bd202f;
  border: 2px solid #fff;
  transition: outline-offset 250ms linear;
  cursor: pointer;
  margin: 0 10px;
}

.cookie_button:hover,
.cookie_button:focus {
  outline-offset: -8px;
}

.main-news-slider-area {
  margin-bottom: 120px;
}

.slider-content {
  background: linear-gradient(180deg, transparent 0%, #bd202f96 64%, #111 100%);
}

.slider-content > .tag {
  background-color: transparent !important;
  padding: 3px 5px;
  border-radius: 4px;
  color: #fff !important;
  border: 1px solid #fff;
}

.side_card_container {
  display: flex;
  flex-flow: column;
  gap: 10px;
}

.news_card {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  min-height: 19rem;
  height: 100%;
  width: 100%;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.32);
}

.news_card::after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background: linear-gradient(180deg, transparent 0%, rgba(17, 17, 17, 0.5662640056) 73%);
  pointer-events: none;
  z-index: 1;
}

.news_card_image_holder {
  width: 100%;
  height: 100%;
}

.news_card_image_holder a {
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 5px;
  text-align: center;
  overflow: hidden;
  position: relative;
}

.news_card_image {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.news_card_content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(180deg, transparent 0%, #bd2030a1 64%, #111111 100%);
  padding: 20px;
  z-index: 2;
}

.news_card_content > span {
  padding: 3px 5px;
  border-radius: 4px;
  color: #fff !important;
  border: 1px solid #fff;
}

.news_card_content > h3 {
  width: fit-content;
  font-size: 1.3rem;
  margin: 10px 0;
}

.news_card_content > h3 a {
  position: relative;
}

.news_card_content > h3 a::before {
  content: "";
  position: absolute;
  width: 0%;
  height: 100%;
  left: 0;
  top: 0;
  border-bottom: 1px solid #fff;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  z-index: -1;
}

.news_card:hover .news_card_content > h3 a::before {
  width: 100%;
}

.news_card_content a {
  color: #fff !important;
}

.news_card_content a:hover {
  color: #ff661f !important;
}

.news_card_content_date {
  color: white !important;
}

.news_minicard {
  display: flex;
  background: radial-gradient(circle, #bd202f 0%, #111111 100%);
  border-radius: 10px;
  padding: 10px;
  position: relative;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.32);
  align-items: center;
}

.news_minicard_image_holder {
  width: 100px;
  height: 100px;
  overflow: unset !important;
  border-radius: 10px;
}
@media (min-width: 575px) {
  .news_minicard_image_holder {
    width: 150px;
    height: 150px;
  }
}

.news_minicard_image {
  display: block;
  width: 100px;
  height: 100px;
  object-fit: cover;
  object-position: center;
  max-width: unset !important;
  border-radius: 10px;
}
@media (min-width: 575px) {
  .news_minicard_image {
    width: 150px;
    height: 150px;
  }
}

.news_minicard_content {
  padding: 0 10px;
  padding-bottom: 30px;
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  min-height: 100px;
}
@media (min-width: 575px) {
  .news_minicard_content {
    min-height: 150px;
  }
}

.news_minicard_content > span {
  font-size: 0.65rem;
  padding: 3px 5px;
  border-radius: 4px;
  color: #fff !important;
  border: 1px solid #fff;
}
@media (min-width: 575px) {
  .news_minicard_content > span {
    font-size: 0.8rem;
  }
}

.news_minicard_content > h3, .news_minicard_content_date {
  margin-bottom: 0 !important;
}

.news_minicard_content h3 {
  margin-top: 10px;
  line-height: 1.1rem;
  font-weight: 400;
  font-size: 0.85rem;
}
@media (min-width: 575px) {
  .news_minicard_content h3 {
    line-height: 1.4rem;
    font-size: 1.4rem;
  }
}

.news_minicard_content h3 a {
  color: #fff !important;
}
@media (min-width: 575px) {
  .news_minicard_content h3 a {
    font-size: 1.2rem;
  }
}

.news_minicard_content_date {
  color: white !important;
  position: absolute;
  bottom: 0px;
  left: 10px;
  font-weight: 300;
  font-size: 0.8rem;
}

.news_minicard_content_date > a {
  color: #ff661f !important;
}

.dark .red-title {
  color: #ff661f !important;
}

.dark .section-title::before {
  background: #ff661f !important;
}

.dark .news_minicard {
  background: unset !important;
}

/* popup */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  transform: translateY(-120%);
  opacity: 0;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.7);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-div {
  position: relative;
  transition: transform 150ms ease-in;
  z-index: 1001;
  box-sizing: border-box;
  min-width: 300px;
  min-height: 200px;
  max-width: 100vw;
  max-height: 100vh;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  scroll-behavior: smooth;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.show-popup {
  transform: translateY(0);
  pointer-events: auto;
  opacity: 1;
}

.popup-close {
  position: fixed;
  z-index: 24;
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50px;
  background-color: white;
}

.popup-close-icon {
  transform: scale(2.3);
}

.popup-close-icon::before {
  vertical-align: middle !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (min-width: 768px) {
  .popup-div {
    width: 90vw;
    height: 85vh;
  }
}
@media screen and (min-width: 992px) {
  .popup-div {
    max-width: 980px;
  }
}
.my_preloader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  transition: opacity 0.3s linear;
}

.preloader {
  background-color: unset !important;
  width: 100vw !important;
  background: radial-gradient(circle, #bd202f 0%, #111111 100%) !important;
}

.ripple_effect {
  position: relative;
  overflow: hidden !important;
}

span.ripple {
  position: absolute;
  border-radius: 50%;
  transform: scale(0);
  animation: ripple 300ms linear;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 9999;
  opacity: 0.5;
}

@keyframes ripple {
  to {
    transform: scale(3);
    opacity: 0;
  }
}
.flipIn {
  animation: flipIn 600ms ease-out;
  transform-origin: 50vw 50vh;
  overflow: hidden;
}

@keyframes flipIn {
  0% {
    transform: scale(0) rotate(0);
    opacity: 0;
  }
  100% {
    transform: scale(1) rotate(1080deg);
    opacity: 1;
  }
}
.scaleIn {
  animation: scaleIn 500ms ease-out;
  transform-origin: 50vw 50vh;
}

@keyframes scaleIn {
  0% {
    transform: scale(0.9);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
.translateIn {
  animation: translateIn 300ms ease-out;
  overflow: hidden;
}

@keyframes translateIn {
  0% {
    opacity: 0;
  }
  90% {
    transform: translateX(20%);
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
.scrollme {
  perspective: 600px;
}

.hideNav {
  transform: translateY(-40%);
  opacity: 0;
  pointer-events: none;
}