$blue_1: #336699;
$blue_2: #66ccff;

$red_1: #bd202f;
$red_2: #ff661f;


$box_shadow_0: 0px 0px 14px rgba(0, 0, 0, 0.32);
$box_shadow_1: 0px 2px 6px rgba(0, 0, 0, 0.15);
$box_shadow_2: 0px 0px 14px rgba(0, 0, 0, 0.35);
$box_shadow_3: 0px 3px 3px rgba(0, 0, 0, 0.25);
$box_shadow_4: 0px 0px 8px rgba(0, 0, 0, 0.45);
