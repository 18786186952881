.ripple_effect {
    position: relative;
    overflow: hidden !important;
    // transition: all 400ms ease-in-out;
}
span.ripple {
    position: absolute;
    border-radius: 50%;
    transform: scale(0);
    animation: ripple 300ms linear;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 9999;
    opacity: 0.5;
}
@keyframes ripple {
    to {
        transform: scale(3);
        opacity: 0;
    }
}