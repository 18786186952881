.banner_container {
    max-height: 283px;
    overflow: hidden;
    text-align: center;
    display: block;
}
.banner_image {
    object-fit: cover;
}
.banner_mobile {
    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.856);
    z-index: 5;
}
.close_banner_mobile_icon {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 6;
    padding: 8px;
}

// cookies
.cookie_buttons_container {
    position: fixed;
    z-index: 10;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // display: none;
    justify-content: center;
    /* line-height: 1.4; */
    padding: 15px;
    // background-color: $red_1;
    
    background: linear-gradient(90deg, #111 0%, $red_1 40%);
}
.cookie_text {
    color: #fff;
}
.cookie_button {
    padding: 10px 20px;
    color: #fff;
    outline: 2px solid #fff !important;
    background-color: $red_1;
    border: 2px solid #fff;
    transition: outline-offset 250ms linear;
    cursor: pointer;
    margin: 0 10px;
}
.cookie_button:hover,
.cookie_button:focus {
    outline-offset: -8px;
}