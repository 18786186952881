.top-header-area {
    // background-color: unset;
    background: radial-gradient(circle, $red_1 0%, rgba(17,17,17,1) 100%);
}
.navbar {
    align-items: stretch !important;
}
.navbar-area {
    position: sticky !important;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    
    background: radial-gradient(circle, $red_1 0%, rgba(17,17,17,1) 100%) !important;
    transition: 0.5s;
}

.meanmenu-reveal span {
    background-color: #fff !important;
}
.meanclose {
    color: #fff !important;
}
.mean-container .mean-nav ul li a.mean-expand {
    text-align: center;
    width: 24px !important;
    // border-left: 1px dashed rgba(255, 255, 255, 0.158) !important;
    box-shadow: inset 0px 0px 12px rgba(0, 0, 0, 0.3);
}
.mean-container .mean-nav ul li a.mean-clicked {
    box-shadow: inset 0px 0px 12px rgba(0, 0, 0, 0.704);
}
.mean-nav {
    background: radial-gradient(circle, $red_1 0%, rgba(17,17,17,1) 100%) !important;
    overflow-x: hidden;
    max-height: 70vh;
}
.mean-nav .dropdown-menu, .mean-nav .nav-item .dropdown-menu {
    background: radial-gradient(circle, $red_1 0%, rgba(17,17,17,1) 100%) !important;
    // height: auto !important;
}
.mean-clicked {
    background-color: rgba(255, 255, 255, 0.247) !important;
}


.main-navbar {
    background: radial-gradient(circle, $red_1 0%, rgba(17,17,17,1) 100%);
    box-shadow: $box_shadow_4 !important;
}
.main-navbar a, .mean-nav a{
    color: #fff !important;
    background-color: transparent !important;
    border-top-color: transparent !important;
}
.main-navbar a:hover {
    color: $red_2 !important;
}
.main-navbar .dropdown-menu {
    background: radial-gradient(circle, $red_1 0%, rgba(17,17,17,1) 100%) !important;
    border-radius: 10px !important;
}
.logo_img {
    height: 45px;
}
.search_button {
    padding:0 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color:transparent;
    color: #fff;
    font-weight: bold;
    // height: 84px;
    // height: 100%;
    cursor: pointer;
    outline: none;
    border: none;
    transition: all 250ms ease-in-out;
    transition-property: color, background-color;
    position: relative;
}
.search_button:hover {
    color: $red_2;
}
.search_container {
    width: 90%;
    max-width: 500px;
}
.search_container ::placeholder {
    color: rgba(255, 255, 255, 0.788);
}
.search_input {
    padding: 15px;
    // width: 100%;
    flex-grow: 1;
    border: 2px solid #fff;
    outline: 2px solid #fff !important;
    transition: outline-offset 250ms linear;
    background-color: transparent;
    color: #fff;
}
.search_button_submit {
    padding: 10px 20px;
    color: #fff;
    outline: 2px solid #fff !important;
    background-color: transparent;
    border: 2px solid #fff;
    transition: outline-offset 250ms linear;
    cursor: pointer;
    margin: 0 10px;
    min-width: 60px;
}
.search_button_submit:hover,
.search_button_submit:focus,
.search_input:hover,
.search_input:focus {
    outline-offset: -8px;
}

// .main-navbar {
//     width: 100vw !important;
// }