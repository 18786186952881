.my_preloader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    background: #fff;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    transition: opacity 0.3s linear;
}
.preloader {
    background-color: unset !important;
    width: 100vw !important;
    background: radial-gradient(circle, $red_1 0%, rgba(17,17,17,1) 100%) !important;
}